*{
  font-family: 'Kanit', sans-serif;
font-family: 'Ubuntu', sans-serif;
}
#free { 
  position: relative;
  text-align: center;
  color: white;
}
#cons{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
 
}